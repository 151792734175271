import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { RootState } from '../store/reducers';
import { Go, HttpRequestFail, HttpRequestSuccess } from '../store/actions';
import { Session } from '../../shared/models';

@Injectable()
export class HttpRequestInterceptor extends Session implements HttpInterceptor {
  constructor(private store: Store<RootState>) {
    super();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
          this.store.dispatch(new HttpRequestSuccess(res));
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.deleteSession();
          this.store.dispatch(new Go({path: ['login']}));
        }
        this.store.dispatch(new HttpRequestFail(err));
        return throwError(err);
      })
    );
  }
}
