import { FilterActions, FilterActionsTypes } from '../actions';
import { IPosition, ISection } from '../../../shared/interfaces';

export interface FilterState {
  loading: boolean;
  loaded: boolean;
  countries: string[];
  jobDataFilter: {
    sections: ISection[],
    positions: IPosition[]
  };
}

export const initialFilterState: FilterState = {
  loading: false,
  loaded: false,
  countries: [],
  jobDataFilter: {
    sections: [],
    positions: []
  }
};

export function filterReducer(
  state: FilterState = initialFilterState,
  actions: FilterActions
): FilterState {
  switch (actions.type) {
    case FilterActionsTypes.LOAD_COUNTRIES: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case FilterActionsTypes.LOAD_COUNTRIES_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }
    case FilterActionsTypes.LOAD_COUNTRIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        countries: actions.payload.countries
      };
    }
    case FilterActionsTypes.LOAD_JOB_DATA_FILTER_DATA: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case FilterActionsTypes.LOAD_JOB_DATA_FILTER_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        jobDataFilter: actions.payload
      };
    }
    case FilterActionsTypes.LOAD_JOB_DATA_FILTER_DATA_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
