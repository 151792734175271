import { IStatistic, ITable } from '../interfaces';
import { TableCompositor } from './table-compositor.enum';
import { hasOwnProperty } from 'tslint/lib/utils';

export class StatisticTable implements ITable {
  tableView: IStatistic[];
  tableColumns: any[];
  tableColumnsMap: any[];
  fullData: IStatistic;
  tableType: 'COMMON';
  microTableView: any[] = [];
  microTableColumns: any[] = [];
  microTableColumnsMap: any[] = [];


  constructor(data: IStatistic) {
    this.fullData = data;
    this.microTableView = [{
      completed_50: data.completed_50 || 0,
      completed_75: data.completed_75 || 0,
      registered_users: data.registered_users || 0,
      total_registered_users: data.total_registered_users || 0
    }];
    this.microTableColumnsMap = StatisticTable.getData(data);
    this.microTableColumns = this.microTableColumnsMap.map((value) => value.key);
  }

  private static getData(data: IStatistic): any[] {
    const tables: any[] = [];
    for (const key in data) {
      if (hasOwnProperty(data, key)) {
        tables.push({
          name: TableCompositor[key],
          key: key
        });
      }
    }
    return tables;
  }
}
