import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IPosition, ISection } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class JobFilterService {

  constructor(private http: HttpClient) { }

  getJobDataFilterData(): Observable<{
    sections: ISection[],
    positions: IPosition[]
  }> {
    return this.http.get<{
      sections: ISection[],
      positions: IPosition[]
    }>('/all/jobDataFilter')
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }
}
