import { HttpActions, HttpActionTypes } from '../actions';

export interface HttpState {
  isError: boolean;
  status: number;
  error: string;
}

const initialHttpState: HttpState = {
  isError: false,
  status: 0,
  error: undefined
};

export function httpReducer(
  state: HttpState = initialHttpState,
  actions: HttpActions
): HttpState {
  switch (actions.type) {
    case HttpActionTypes.HTTP_REQUEST_SUCCESS: {
      return {
        ...state,
        isError: false,
        status: actions.payload.status
      };
    }
    case HttpActionTypes.HTTP_REQUEST_FAIL: {
      return {
        ...state,
        isError: true,
        status: actions.payload.status,
        error: actions.payload.message
      };
    }
    default: {
      return state;
    }
  }
}
