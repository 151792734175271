import { NavigationExtras } from '@angular/router';

import { Action } from '@ngrx/store';

export const GO = '[ROUTER] Go';
export const BACK = '[ROUTER] Back';
export const FORWARD = '[ROUTER] Forward';
export const UPDATE_ROUTE_NAME = '[ROUTER] Update Route Name';

export class Go implements Action {
  readonly type = GO;

  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

export class Back implements Action {
  readonly type = BACK;
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export class RouteName {
  constructor(
    public payload: {
      routerName: string;
    }
  ) {}
}

export type RouterActionsUnion = Go | Back | Forward;
