import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';

import { CustomSerializer } from './router';

import { metaReducers, rootReducer } from './store/reducers';
import { RootEffects } from './store/effects';

import { environment } from '../../environments/environment';

import { HttpInterceptorProviders } from './http';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(rootReducer, {metaReducers}),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router'
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        name: 'GMC.admin'
      })
      : [],
    EffectsModule.forRoot(RootEffects)
  ],
  declarations: []
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        HttpInterceptorProviders,
        { provide: RouterStateSerializer, useClass: CustomSerializer },
      ]
    };
  }
}
