import { Action } from '@ngrx/store';
import { ICountries } from '../../../shared/interfaces';

export enum FilterActionsTypes {
  LOAD_COUNTRIES = '[FILTER] Load Countries',
  LOAD_COUNTRIES_SUCCESS = '[FILTER] Load Countries Success',
  LOAD_COUNTRIES_FAIL = '[FILTER] Load Countries Fail',
  LOAD_JOB_DATA_FILTER_DATA = '[FILTER] Load Job Data Filter Data',
  LOAD_JOB_DATA_FILTER_DATA_SUCCESS = '[FILTER] Load Job Data Filter Data Success',
  LOAD_JOB_DATA_FILTER_DATA_FAIL = '[FILTER] Load Job Data Filter Data Fail',
}

export class LoadCountries implements Action {
  readonly type = FilterActionsTypes.LOAD_COUNTRIES;

  constructor() {}
}

export class LoadCountriesSuccess implements Action {
  readonly type = FilterActionsTypes.LOAD_COUNTRIES_SUCCESS;

  constructor(public payload: ICountries) {}
}

export class LoadCountriesFail implements Action {
  readonly type = FilterActionsTypes.LOAD_COUNTRIES_FAIL;

  constructor() {}
}

export class LoadJobDataFilterData implements  Action {
  readonly type = FilterActionsTypes.LOAD_JOB_DATA_FILTER_DATA;

  constructor() {}
}

export class LoadJobDataFilterDataSuccess implements  Action {
  readonly type = FilterActionsTypes.LOAD_JOB_DATA_FILTER_DATA_SUCCESS;

  constructor(public payload: any) {}
}


export class LoadJobDataFilterDataFail implements  Action {
  readonly type = FilterActionsTypes.LOAD_JOB_DATA_FILTER_DATA_FAIL;

  constructor() {}
}


export type FilterActions =
  | LoadCountries
  | LoadCountriesSuccess
  | LoadCountriesFail
  | LoadJobDataFilterData
  | LoadJobDataFilterDataSuccess
  | LoadJobDataFilterDataFail;
