export enum TableCompositor {
  total_registered_users = 'Total Registered, Users',
  registered_users = 'Registered, Users',
  completed_50 = 'Completed 50% CV, Users',
  completed_75 = 'Completed 75% CV, Users',
  balance = 'Balance, $',
  total_income = 'Total Income, $',
  total_earned = 'Total Earned, $',
  total_withdraw = 'Total Pay OUT, $',
  total_spend = 'Total Spent, $',
  country = 'Country',
  section = 'Section',
  vessel_type = 'Job/Vessel Type',
  vessel = 'Job/Vessel',
  category = 'Position Category',
  position = 'Position',
  status = 'Status',
  date = 'Date',
  total_spent = 'Total Spent, $',
  status_spent = 'Spent for Status, $',
  STANDARD_pcs = 'Standard, pcs',
  STANDARD = 'Standart, $',
  PREMIUM_pcs	= 'Premium, pcs',
  PREMIUM = 'Premium, $',
  VIP_pcs = 'VIP, pcs',
  VIP = 'VIP, $',
  total = 'Total Revalidation, $',
  total_pay_out = 'Total Pay Out, $',
}
