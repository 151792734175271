import { PageActions, PageActionTypes } from '../actions';

export interface PageState {
  mainTitle: string;
  childTitle: string;
  hasChildren: boolean;
  sidebarOpened: boolean;
}

const initialPageState: PageState = {
  mainTitle: undefined,
  childTitle: undefined,
  hasChildren: false,
  sidebarOpened: true
};

export function pageReducer(
  state: PageState = initialPageState,
  actions: PageActions
): PageState {
  switch (actions.type) {
    case PageActionTypes.SET_PAGE_TITLE: {
      return {
        ...state,
        mainTitle: actions.payload.mainTitle
      };
    }
    case PageActionTypes.SET_CHILD_TITLE: {
      return {
        ...state,
        childTitle: actions.payload.childTitle
      };
    }
    case PageActionTypes.RESET_CHILD_TITLE: {
      return {
        ...state,
        childTitle: undefined
      };
    }
    case PageActionTypes.TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarOpened: !state.sidebarOpened
      };
    }
    default: {
      return state;
    }
  }
}
