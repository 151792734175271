import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Action } from '@ngrx/store';

export enum HttpActionTypes {
  HTTP_REQUEST_SUCCESS = '[HTTP] Request Success',
  HTTP_REQUEST_FAIL = '[HTTP] Request Fail'
}

export class HttpRequestSuccess implements Action {
  readonly type = HttpActionTypes.HTTP_REQUEST_SUCCESS;

  constructor(public payload: HttpResponse<any>) {}
}

export class HttpRequestFail implements Action {
  readonly type = HttpActionTypes.HTTP_REQUEST_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

export type HttpActions = HttpRequestSuccess | HttpRequestFail;
