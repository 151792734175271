export class Session {
  sessionName: string;

  protected constructor() {
    this.sessionName = 'currentUser';
  }

  get session(): string {
    return localStorage.getItem(this.sessionName);
  }

  createSession(token: string, continues: boolean) {
    if (!token) {
      return;
    }
    continues
      ? localStorage.setItem(this.sessionName, token)
      : sessionStorage.setItem(this.sessionName, token);
  }

  deleteSession() {
    localStorage.removeItem(this.sessionName);
    sessionStorage.removeItem(this.sessionName);
  }

  checkSession(): any {
    return localStorage.getItem(this.sessionName);
  }
}
