import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';

import { Session } from '../../shared/models';
import { Go } from '../store/actions';
import { Store } from '@ngrx/store';
import { RootState } from '../store/reducers';

@Injectable({
  providedIn: 'root'
})
export class CanLoadAdminGuard extends Session implements CanLoad {

  constructor(private store: Store<RootState>) {
    super();
  }

  canLoad() {
    if (this.checkSession()) {
      return true;
    }

    this.store.dispatch(new Go({ path: ['login'] }));
    return false;
  }
}
