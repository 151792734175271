import { Params } from '@angular/router';

import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';

import { pageReducer, PageState } from './page.reducer';

import { environment } from '../../../../environments/environment';
import { IRouterData } from '../../../shared/interfaces';
import { httpReducer, HttpState } from './http.reducer';
import { filterReducer, FilterState } from './filter.reducer';

export interface RootState {
  router: RouterReducerState<RouterStateUrl>;
  page: PageState;
  http: HttpState;
  filter: FilterState;
}

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: IRouterData;
}

export const rootReducer: ActionReducerMap<RootState> = {
  router: routerReducer,
  page: pageReducer,
  http: httpReducer,
  filter: filterReducer
};

export const getRootState = (state: RootState) => state;

export const metaReducers: MetaReducer<RootState>[] = !environment.production
  ? []
  : [];
