import { ICashflow, ITable } from '../interfaces';
import { TableCompositor } from './table-compositor.enum';
import { hasOwnProperty } from 'tslint/lib/utils';

export class CashflowTable implements ITable {
  data: ICashflow;
  tableView: ICashflow[];
  tableColumns: any[] = [];
  tableColumnsMap: any[] = [];
  fullData: ICashflow;
  tableType: 'COMMON';
  microTableView: any[] = [];
  microTableColumns: any[] = [];
  microTableColumnsMap: any[] = [];

  constructor(data: ICashflow) {
    this.fullData = data;
    this.data = {
      balance: data.balance || 0,
      total_earned: data.total_earned || 0,
      total_income: data.total_income || 0,
      total_spend: data.total_spend || 0,
      total_withdraw: data.total_withdraw || 0
    };
    this.microTableView = [{...this.data}];
    this.microTableColumnsMap = CashflowTable.getData(this.data);
    this.microTableColumns = this.tableColumnsMap.map((value) => value.key);
  }

  private static getData(data: ICashflow): any[] {
    const tables: any[] = [];
    for (const key in data) {
      if (hasOwnProperty(data, key)) {
        tables.push({
          name: TableCompositor[key],
          key: key
        });
      }
    }
    return tables;
  }
}
