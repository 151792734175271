import { ICommonData, ITable } from '../interfaces';
import { hasOwnProperty } from 'tslint/lib/utils';
import { TableCompositor } from './table-compositor.enum';

export class CommonTable implements ITable {
  tableView: any[];
  tableColumns: any[];
  tableColumnsMap: any[];
  fullData: any;
  tableType: 'COMMON';
  microTableView: any[] = [];
  microTableColumns: any[] = [];
  microTableColumnsMap: any[] = [];

  constructor(data: { data: ICommonData; [p: string]: any }) {
    this.fullData = data;
    this.tableView = Object.keys(data.data).map((key) => {
      if (!!Object.keys(data.data[key]).length) {
        return { country: key, ...data.data[key] };
      }
    }).filter((item) => !!item);

    if (!!this.tableView.length) {
      this.tableColumnsMap = CommonTable.getData(this.tableView[0]);
      this.tableColumns = this.tableColumnsMap.map((value) => value.key);
    }

    this.microTableColumnsMap = CommonTable.getMicroTable(data);
    this.microTableView = [{...data, data: undefined}];
  }

  private static getData(data: ICommonData): any[] {
    const tables: any[] = [];
    for (const key in data) {
      if (hasOwnProperty(data, key)) {
        tables.push({
          name: TableCompositor[key],
          key: key
        });
      }
    }
    return tables;
  }

  private static getMicroTable(data: any): any[] {
    const tables: any[] = [];
    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== 'data') {
        tables.push({
          name: TableCompositor[key],
          key: key
        });
      }
    }
    return tables;
  }


}
