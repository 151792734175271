export enum PageActionTypes {
  SET_PAGE_TITLE = '[PAGE] Set Page Title',
  SET_CHILD_TITLE = '[PAGE] Set Child Title',
  RESET_CHILD_TITLE = '[PAGE] Reset Child Title',
  TOGGLE_SIDEBAR = '[PAGE] Toggle Sidebar'
}

export class SetPageTitle {
  readonly type = PageActionTypes.SET_PAGE_TITLE;

  constructor(public payload: { mainTitle: string }) {}
}

export class SetChildTitle {
  readonly type = PageActionTypes.SET_CHILD_TITLE;

  constructor(public payload: { childTitle: string }) {}
}

export class ResetChildTitle {
  readonly type = PageActionTypes.RESET_CHILD_TITLE;
}

export class ToggleSidebar {
  readonly type = PageActionTypes.TOGGLE_SIDEBAR;
}

export type PageActions =
  | SetPageTitle
  | SetChildTitle
  | ResetChildTitle
  | ToggleSidebar;
