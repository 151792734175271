import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest, HttpParams
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { Session } from '../../shared/models';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { Moment } from 'moment';
import { el } from '@angular/platform-browser/testing/src/browser_util';

@Injectable()
export class JwtInterceptor extends Session implements HttpInterceptor {

  constructor() {
    super();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const absolutePath = `https://admin-${!environment.production ? 'dev-' : ''}api.globalmarinecity.com`;
    let params: HttpParams;
    if (req.params.has('from') && req.params.has('to')) {
      const from = moment(req.params.get('from')).unix().toString();
      const to = moment(req.params.get('to')).unix().toString();
      params = req.params.set('from', from).set('to', to);
    } else {
      params = req.params;
    }
    const withJwt = req.clone({
      url: absolutePath + req.url,
      params: params,
      setHeaders: this.session
        ? { Authorization: `Bearer ${this.session}` }
        : {}
    });

    return next.handle(withJwt);
  }
}
