import { IAuth } from '../interfaces';

export class AuthModel implements IAuth {
  email: string;
  login: string;
  password: string;

  constructor (auth: IAuth) {
    this.email = auth.email;
    this.password = auth.password;
    this.login = auth.login;
  }
}
