import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { FilterActionsTypes, LoadCountriesSuccess, LoadJobDataFilterDataSuccess } from '../actions';

import { CountryService } from '../../services/country.service';
import { JobFilterService } from '../../services/job-filter.service';

@Injectable()
export class FilterEffects {
  constructor(
    private countryService: CountryService,
    private jobFilterService: JobFilterService,
    private actions$: Actions
  ) {}

  @Effect()
  filter$: Observable<Action> = this.actions$.pipe(
    ofType(FilterActionsTypes.LOAD_COUNTRIES),
    mergeMap(() =>
      this.countryService
        .getAllCountries()
        .pipe(map((data) => new LoadCountriesSuccess(data)))
    )
  );

  @Effect()
  jobDataFilter$: Observable<Action> = this.actions$.pipe(
    ofType(FilterActionsTypes.LOAD_JOB_DATA_FILTER_DATA),
    mergeMap(() =>
      this.jobFilterService
        .getJobDataFilterData()
        .pipe(map((data) => new LoadJobDataFilterDataSuccess(data))))
  );
}
